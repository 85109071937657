import { useFlags } from '@atlaskit/flag';
import { ICustomer, IStoredCustomer } from 'core/api/customers/customers-api-interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import PageHeader from 'shared/components/page-header/page-header';
import PageHeaderSkeleton from 'shared/components/page-header/page-header-skeleton';
import { CustomerTab, customerTabs } from 'core/constants/customer-tab';
import SharedTabButton from 'shared/components/buttons/tab-button/tab-button';
import CustomerOverviewNotes from './customer-overview-notes/customer-overview-notes';
import CustomerOverviewDetails from './customer-overview-details/customer-overview-details';
import CustomerOverviewAppointments from './customer-overview-appointments/customer-overview-appointments';
import CustomerOverviewSurvey from './customer-overview-survey/customer-overview-survey';
import CustomerOverviewDocuments from './customer-overview-documents/customer-overview-documents';
import { useSelector } from 'react-redux';
import { CustomersSlice } from '../customers-slice';
import { PageElement } from 'core/utilities/interface-helpers';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { CustomerStatuses, getCustomerStatusFromKey } from 'core/constants/customer-status';
import CustomersApiService from 'core/api/customers/customers-api.service';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { LoadingButton } from '@atlaskit/button';
import { BookingStatus, BookingStatuses } from 'core/constants/booking-status';
import { useDialog } from 'core/providers/DialogProvider';
import CallBackDialog from '../callback-dialog/callback-dialog';
import MissedCallDialog from '../missed-call-dialog/missed-call-dialog';
import { useAuthState } from 'core/providers/AuthProvider';
import { IUser } from 'core/api/users/users-api-interface';
import { showErrorFlag } from 'core/utilities/flags-helper';
import { multiTimestampsToSeconds } from 'core/utilities/date-helpers';
import { Unsubscribe } from 'firebase/firestore';
import CustomerOverviewOrders from './customer-overview-orders/customer-overview-orders';
import CustomerOverviewAudiogram from './customer-overview-audiogram/customer-overview-audiogram';
import { useResponsiveState } from 'core/providers/ResponsiveProvider';
import { Permissions } from 'core/constants/permissions';
import CustomerOverviewAuditLogs from './customer-overview-audit-logs/customer-overview-audit-logs';
import { getObjectChanges } from 'core/utilities/object-helpers';
import { createAddressString } from 'core/utilities/address-string-builder';

const CustomerOverview = () => {
  // Local State
  const [customerData, setCustomerData] = useState<IStoredCustomer>();
  const { isMobile } = useResponsiveState();
  const defaultTab = isMobile ? CustomerTab.DETAILS : CustomerTab.SURVEY;
  const [selectedTab, setSelectedTab] = useState<string>(defaultTab);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  // Hooks
  const { uid } = useParams();
  const flags = useFlags();
  const customers = useSelector(CustomersSlice.selectAll);
  const dialog = useDialog();
  const { userData, userPermissions } = useAuthState();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const tabs = useMemo(() => {
    const processedTabs = [...customerTabs];

    if (isMobile) {
      processedTabs.unshift({
        label: 'Details',
        key: CustomerTab.DETAILS,
      });
    }

    if (userPermissions?.includes(Permissions.AUDIT_LOG_GET)) {
      processedTabs.splice(processedTabs.length, 0, {
        label: 'Audit log',
        key: CustomerTab.AUDIT_LOG,
      });
    }
    return processedTabs;
  }, [isMobile, userPermissions]);

  if (userPermissions?.includes(Permissions.AUDIT_LOG_GET)) {
    tabs.push();
  }
  useEffect(() => {
    const tabSelectionValid = tabs.some((ct) => ct.key === tab);
    if (tab && tabSelectionValid) {
      setSelectedTab(tab);
    } else {
      setSearchParams(`tab=${defaultTab}`, { replace: true });
    }
  }, [defaultTab, setSearchParams, tab, tabs]);

  const handleSubscriptionError = useCallback(
    (error: any) => {
      showErrorFlag('An error occurred', 'Existing appointments could not be retrieved, please try again.', flags);
    },
    [flags]
  );

  const createCustomerSubscription = useCallback(
    (uid: string) => {
      const handleSnapshot = (data: ICustomer) => {
        const { lastUpdated, createdAt, statusUpdatedAt } = data;
        const storedCustomer: IStoredCustomer = {
          ...data,
          ...multiTimestampsToSeconds({
            lastUpdated,
            createdAt,
            statusUpdatedAt,
          }),
        };
        setCustomerData(storedCustomer);
      };
      return CustomersApiService.subscribeToCustomer(uid, handleSnapshot, handleSubscriptionError);
    },
    [handleSubscriptionError]
  );

  useEffect(() => {
    if (uid) {
      const customer = customers.find((customer) => customer.uid === uid);
      if (customer) {
        setCustomerData(customer);
      } else {
        let unsubscribe: Unsubscribe;
        unsubscribe = createCustomerSubscription(uid);
        return () => {
          unsubscribe();
        };
      }
    }
  }, [createCustomerSubscription, customers, flags, uid]);

  const getTabContent = () => {
    switch (selectedTab) {
      case CustomerTab.DETAILS:
        return <CustomerOverviewDetails customer={customerData!} />;
      case CustomerTab.SURVEY:
        return <CustomerOverviewSurvey customer={customerData!} />;
      case CustomerTab.AUDIOGRAM:
        return <CustomerOverviewAudiogram customer={customerData!} />;
      case CustomerTab.APPOINTMENTS:
        return <CustomerOverviewAppointments customer={customerData!} />;
      case CustomerTab.ORDERS:
        return <CustomerOverviewOrders customer={customerData!} />;
      case CustomerTab.DOCUMENTS:
        return <CustomerOverviewDocuments customer={customerData!} />;
      case CustomerTab.NOTES:
        return <CustomerOverviewNotes customerId={uid!} customerName={customerData?.fullName!} />;
      case CustomerTab.AUDIT_LOG:
        return <CustomerOverviewAuditLogs customer={customerData!} />;
    }
  };

  const handleStatusSelection = async (newStatus: string) => {
    setUpdatingStatus(true);
    const { fullName, uid: userUid } = userData as IUser;
    const author = { fullName, uid: userUid };
    switch (newStatus) {
      case BookingStatus.FIRST_ATTEMPT:
      case BookingStatus.SECOND_ATTEMPT:
      case BookingStatus.THIRD_ATTEMPT:
      case BookingStatus.FOURTH_ATTEMPT:
      case BookingStatus.EMAIL_SMS:
        const p1 = {
          bookingStatus: newStatus,
          statusUpdatedBy: author,
        };
        await CustomersApiService.update(customerData?.uid!, author, p1, getObjectChanges(p1, customerData!));
        dialog?.openDialog(<MissedCallDialog customer={customerData!} />);
        break;
      case BookingStatus.CALLBACK_LATER:
        dialog?.openDialog(<CallBackDialog customer={customerData!} />);
        break;
      default:
        const p2 = {
          status: newStatus,
          statusUpdatedBy: author,
        };
        await CustomersApiService.update(customerData?.uid!, author, p2, getObjectChanges(p2, customerData!));
    }
    setUpdatingStatus(false);
  };

  const currentStatus = getCustomerStatusFromKey(customerData?.status);
  const statusesToTransitionTo = [...CustomerStatuses, ...BookingStatuses].filter(
    (status) =>
      (status.key === BookingStatus.CALLBACK_LATER ||
        (status.key !== customerData?.status && status.key !== customerData?.bookingStatus)) &&
      currentStatus?.transitionTo.some((transition) => transition === status.key)
  );

  const headerActions: PageElement[] = [
    {
      key: 'addCustomer',
      element: (
        <DropdownMenu
          trigger={({ triggerRef, ...props }) => (
            <LoadingButton
              isLoading={updatingStatus}
              isDisabled={updatingStatus}
              {...props}
              iconAfter={<ChevronDownIcon label='more' />}
              ref={triggerRef}
            >
              Update status
            </LoadingButton>
          )}
        >
          <DropdownItemGroup>
            {statusesToTransitionTo.map((status) => (
              <DropdownItem onClick={() => handleStatusSelection(status.key)} key={status.key}>
                {status.label}
              </DropdownItem>
            ))}
            {statusesToTransitionTo.length === 0 && <DropdownItem>No options</DropdownItem>}
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ];

  return (
    <>
      {!customerData ? (
        <PageHeaderSkeleton actions={headerActions} subtitle={true} />
      ) : (
        <>
          <PageHeader
            title={customerData.fullName}
            subtitle={
              customerData.address
                ? createAddressString([...customerData.address.formatted_address, customerData.address.postcode])
                : 'Unknown'
            }
            actions={headerActions}
            showBack
          />
          <div className='flex flex-grow flex-col lg:flex-row py-6'>
            {!isMobile && <CustomerOverviewDetails customer={customerData} />}
            <div className='lg:pl-6 w-full'>
              <div className='relative'>
                <div className='flex relative z-10 overflow-x-auto'>
                  {tabs.map((tab) => (
                    <SharedTabButton
                      key={tab.key}
                      changeTab={(key: string) => setSearchParams(`tab=${key}`, { replace: true })}
                      tab={tab}
                      currentTab={selectedTab}
                    />
                  ))}
                </div>
                <div className='absolute bottom-0 w-full left-0 right-0 h-px bg-gray-200' />
              </div>
              <div className='mt-6'>{getTabContent()}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomerOverview;
